import React from "react";
import { Navbar, Container, Row, Col } from "react-bootstrap";
import HomeFooter from "../components/Footers/HomeFooter";
import AboutModal from "../components/Modals/AboutModal";
import useLogPageView from "../hooks/useLogPageView";

export default function TermsPage() {
  useLogPageView("Terms", "");
  const [showModal, setShowModal] = React.useState(false);
  const numberedList = {
    listStyleType: 'decimal' /* Numbers */,
  };

  return (
    <div className="main-content bg-main">
      <Navbar bg="transparent">
        <Navbar.Brand href="/">
          <div className="ad-logo-title">
            <h2 className="mb-3">
              ANIMATED <span className="text-info">DRAWINGS</span>
            </h2>
          </div>
        </Navbar.Brand>
      </Navbar>
      <div className="terms-page mb-2">
        <Container className="">
          <Row className="justify-content-center mt-2">
            <Col lg={10} md={10} xs={12}>
              <h2 className="mb-4 text-center">Terms of use</h2>
              <h3>Animated Drawings Demo Supplemental Terms of Service</h3>
              <br/>
              <p>Last Updated: July 29, 2024</p>
              <p>
                These terms are a legally binding agreement between you and Meta Platforms, Inc.,
                or Meta Platform Ireland Limited if you are accessing from the EU/EEA (“Meta”, “we”,
                “our”, or “us”). By clicking “Accept” or by using this Animated Drawings demo
                (the “Demo”), you agree to be bound by these Animated Drawing Demo Supplemental
                Terms of Service (the “Supplemental Terms”), the Meta Terms of Service (
                available at <a href="https://www.facebook.com/terms.php">https://www.facebook.com/terms.php</a> and may be updated from
                time to time), Meta Privacy Policy (available at
                <a href="https://www.facebook.com/privacy/policy/"> https://www.facebook.com/privacy/policy/</a> and may be updated from time
                to time), Meta AIs Terms of Service (the “AI Terms”), and any other applicable
                terms and policies, including the <a href="https://sketch.metademolab.com/usage">Animated Drawings Acceptable Use
                  Policy</a> (collectively the “Meta Terms”), included but not limited to the
                representations, warranties, and waivers set forth below.
              </p>
              <h3 className="text-left">What is the Animated Drawings Demo?</h3>
              <p>
                The “Animated Drawings” Demo allows you to convert two-dimensional
                drawings into animated renderings (“Animations”) based on image
                and user provided annotations that you choose to provide.
                This demo builds upon Detectron2 and AlphaPose. Motion capture data
                comes from the CMU Graphics Motion Capture Lab and Mixamo.
                Built by Jesse Smith, Jessica Hodgins, Somya Jain, Sahir Gomez,
                Somayan Chakrabarti, Clarence Edmondson III, Christopher Gustave,
                Kristin Cooke, Qingyuan Zheng, Yifei Li and friends at Meta AI Research.
              </p>
              <h3 className="text-left">Information Used to Make the Animated
                Drawings Demo Work</h3>
              <p>As part of your use of the Demo, and subject to Meta Terms, you may
                choose to upload an image of your drawing (“Materials”) to
                the Demo as described above. Before the Demo generates an
                Animation, you will have the opportunity to correct the Demo's
                identification, segmentation, and placement of joints on the
                hand drawn characters (collectively “Modifications”). After the image
                is uploaded or selected and Modifications (if any) are made,
                the Demo transforms the image into an Animation. Any Materials
                you upload will be deleted within 90 days. Any Animation
                created from Materials and Modifications is made available
                to you through the Demo, subject to the terms and conditions
                described below.
                <br />
                After an Animation is created from your drawing,
                you will have the option to download or share the generated
                Animation to Facebook and certain other social media platforms.
                If you choose to share your generated Animation on social media,
                then the privacy policy of the operator(s) of the applicable
                social media platform(s) will apply to your sharing of the Animation
              </p>
              <h3 className="text-left">Additional Terms and Conditions</h3>
              <ol style={numberedList}>
                <li>
                  By using the Animated Drawings Demo, you represent, warrant, and agree that: (a) you are at least 18 years old (or the age of majority in the jurisdiction from which you are accessing the Demo); (b) you are not a resident of Illinois or Texas, did not create any Materials in Illinois or Texas, and are not attempting to use the Demo from Illinois or Texas; (c) each item of Materials submitted to the Demo is an original two-dimensional static drawing and does not include or otherwise incorporate any other form of artwork or media (including photographs, videos or music); (d) with respect to each item of Materials submitted to the Demo, you are the creator of such item; (e) you will not allow someone who is under the age of 18 years old (or the age of majority in the jurisdiction from which you are accessing the Demo) to use the Demo; and (f) the Materials do not contain any information that could be used to identify you or any other individual (such as a name or any other personally identifiable information).
                </li>
                <li>
                  Subject to your compliance with Meta Terms, Meta grants to you a limited, non-exclusive, non-sublicensable, non-transferable right to (a) access and use the Demo and (b) access the Animations made available through the Demo, solely for your non-commercial and personal purposes. You agree to neither use nor attempt to use the Animations outside of the Demo or in any manner other than as expressly authorized herein. Meta and its licensors retain all rights in and to the Demo and the Animations that are not expressly granted herein. In the event an Animation is removed from the Demo by Meta, the right to access such Animation granted to you shall terminate. Meta may revoke this right from you at any time and for any reason in its sole discretion.
                </li>
                <li>
                  You grant to Meta and its affiliated companies, licensees and representatives, a perpetual, irrevocable, nonexclusive, royalty-free license to reproduce, distribute, perform and display (publicly or otherwise), modify, create derivative works of, host and otherwise use the Materials, Animations and Modifications in connection with the Demo.
                </li>
                <li>
                  As a large company, Meta takes intellectual property infringement very seriously. You may not submit any content to the Demo that infringes the intellectual property rights of others (e.g., no drawings of Mickey Mouse, please). Also, do not submit content that might be regarded as offensive.
                </li>
                <li>
                  Because Meta will not associate Materials or Animations (including any Modifications) with your name or any other personally identifiable information, you won’t be able to request deletion of submitted Materials or Animations generated from such Materials. You represent and warrant that you will not submit sensitive personal information to the Demo, including but not limited to any information about an identifiable person’s ethnic or racial origin, religious beliefs, health, or sexual orientation. Please ensure that you have read and understood the Meta Terms before submitting Materials, generating Animations or otherwise using the Demo.
                </li>
                <li>
                  The Demo allows you to share Animations generated from your submitted Materials, including your Modifications to such Animations, on Facebook and certain other social media platforms. If you elect to share the Animation on any such social media platforms, your use of the Animation is subject to any additional terms, conditions and policies applicable to such platforms.
                </li>
                <li>
                  In connection with your use of the Demo, you may provide input regarding the accuracy of the resulting Animations (“Feedback”). Because we will not associate your Feedback with your name or any other personally identifiable information, you won’t be able to request access to or deletion of any Feedback that you submit.
                </li>
                <li>You grant to Meta and its affiliates, and its and their representatives and designees, a perpetual, irrevocable, non-exclusive, sublicensable (through multiple tiers), transferable and royalty-free license to reproduce, host, distribute, perform and display (publicly or otherwise), modify, create derivative works of, and otherwise use (collectively, “Use”) the Feedback, including to improve the Demo and/or the associated AI or software program(s), for research and for Meta’s internal business purposes. Meta has no obligation to Use any Feedback that you provide or compensate you for any Feedback that Meta Uses.
                </li>
                <li>The Demo is controlled or operated (or both) from the United States and is not intended to subject Meta to any non-U.S. jurisdiction or law (or, if you are accessing from the EU/EEA, the data controller responsible for your information is Meta Platforms Ireland Limited).
                </li>
                <li>
                These Supplemental Terms are effective until terminated by Meta. Meta reserves the right to terminate your right to access and use the Demo, and your right to access the Animations, for any reason, including but not limited to if you violate these Supplemental Terms or Meta Terms. Further, Meta may terminate these Supplemental Terms and/or discontinue the Demo for any reason. Paragraphs 3-18 will survive termination of these Supplemental Terms.

                </li>
                <li>The Demo and the Animations made available through the Demo are provided to you for entertainment purposes only. Meta makes no representations or warranties of any kind as to the adequacy, accuracy, currency, or completeness of the Demo, any Animations, or any other information or content made available through the Demo. Your use of the Demo is at your own risk. The Demo and the Animations made available through the Demo are provided “AS IS” without representations or warranties of any kind, either express or implied, including without limitation warranties of merchantability, fitness for a particular purpose, or non-infringement or other violation of rights. Meta expressly disclaims any liability for any errors or omissions in the Demo or the Animations. Some jurisdictions may not allow the exclusion of implied warranties, so some of the above exclusions may not apply to you.
                </li>
                <li>In no event will Meta or its affiliates, or its or their directors, officers, employees, licensors, agents, and assigns, be liable for any indirect, special, incidental, consequential, or punitive damages, lost profits, or other non-direct damages whatsoever arising in connection with the use of the Demo, even if one or more of them has been advised of the possibility of such damages or loss. Without limiting the foregoing, the maximum aggregate liability for Meta or its affiliates, or its or their directors, officers, employees, licensors, agents and assigns, for all damages, losses and causes of action, whether in contract, tort (including negligence) or otherwise, arising in connection with the use of the Demo, shall be fifty dollars ($50).
                </li>
                <li>Consistent with your representation, warranty and agreement in subsection (b) of Paragraph 2 above, you hereby expressly waive and release any claim that might be brought by you or on your behalf against Meta or any Meta affiliate alleging that these Supplemental Terms or the conduct described herein fail to comply with or otherwise violate in any respect the Illinois Biometric Information Privacy Act, 740 ILCS 14, or the Texas Capture or Use of Biometric Identifier Act, Tex. Bus. & Com. Code § 503. You further waive and release any claim that might be brought by you or on your behalf against Meta or any Meta affiliate, arising from or related to any use of the Demo or these Supplemental Terms, under either of the aforementioned laws. If any aspect of this Paragraph is deemed invalid or unenforceable, that aspect shall be severed and not affect the validity or enforceability of any other aspect.
                </li>
                <li>For any claim, cause of action, or dispute you have against us that arises out of or relates to these Supplemental Terms or the Demo (“Claim”), you agree that it will be resolved exclusively in the U.S. District Court for the Northern District of California or a state court located in San Mateo County. You also agree to submit to the personal jurisdiction of either of these courts for the purpose of litigating any such Claim, and that the laws of the State of California will govern these Supplemental Terms and any Claim, without regard to conflict of law provisions, including if enforcing this agreed choice of law results in extinguishing a cause of action you may purport to have under another jurisdiction’s law. 
                </li>
                <li>Any delay or failure on our part to enforce a provision of these Supplemental Terms is not a waiver of our right to enforce them later. 
                </li>
                <li>If any provision in these Supplemental Terms is deemed valid or unenforceable, that aspect shall be severed and not affect the validity or enforceability of any other aspect.
                </li>
                <li>In the event of any conflict between these Supplemental Terms and the Meta Terms, these Supplemental Terms will govern solely with respect to your use of the Demo and solely to the extent of the conflict.
                </li>
                <li>We may revise and update these Supplemental Terms from time to time in our sole discretion, so we encourage you to check them regularly for updates. By continuing to access or use the Demo after we post the updated Supplemental Terms on our website or otherwise provide you with notice of changes to these Supplemental Terms, you agree to be bound by the updated Supplemental Terms. If you do not agree to the updated Supplemental Terms, you must stop using the Demo.
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
      </div>
      <HomeFooter
        uuid={"[No ID] - Preload feedback"}
        setShowModal={setShowModal}
      />
      <AboutModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}
