import box_jump from "../assets/motion_examples/box_jump.gif";
import boxing from "../assets/motion_examples/boxing.gif";
import catwalk_walk from "../assets/motion_examples/catwalk_walk.gif";
import dab_dance from "../assets/motion_examples/dab_dance.gif";
import dance from "../assets/motion_examples/dance.gif";
import dance001 from "../assets/motion_examples/dance001.gif";
import dance002 from "../assets/motion_examples/dance002.gif";
import floating from "../assets/motion_examples/floating.gif";
import flying_kick from "../assets/motion_examples/flying_kick.gif";
import happy_idle from "../assets/motion_examples/happy_idle.gif";
import hip_hop_dancing from "../assets/motion_examples/hip_hop_dancing.gif";
import hip_hop_dancing2 from "../assets/motion_examples/hip_hop_dancing2.gif";
import hip_hop_dancing3 from "../assets/motion_examples/hip_hop_dancing3.gif";
import jab_cross from "../assets/motion_examples/jab_cross.gif";
import joyful_jump_l from "../assets/motion_examples/joyful_jump_l.gif";
import jump from "../assets/motion_examples/jump.gif";
import jump_attack from "../assets/motion_examples/jump_attack.gif";
import jump_rope from "../assets/motion_examples/jump_rope.gif";
import punching_bag from "../assets/motion_examples/punching_bag.gif";
import run from "../assets/motion_examples/run.gif";
import run_walk_jump_walk from "../assets/motion_examples/run_walk_jump_walk.gif";
import running_jump from "../assets/motion_examples/running_jump.gif";
import shoot_gun from "../assets/motion_examples/shoot_gun.gif";
import shuffle_dance from "../assets/motion_examples/shuffle_dance.gif";
import skipping from "../assets/motion_examples/skipping.gif";
import standard_walk from "../assets/motion_examples/standard_walk.gif";
import walk_punch_kick_jump_walk from "../assets/motion_examples/walk_punch_kick_jump_walk.gif";
import walk_sway from "../assets/motion_examples/walk_sway.gif";
import walk_swing_arms from "../assets/motion_examples/walk_swing_arms.gif";
import wave_hello_3 from "../assets/motion_examples/wave_hello_3.gif";
import waving_gesture from "../assets/motion_examples/waving_gesture.gif";
import zombie_walk from "../assets/motion_examples/zombie_walk.gif";

const AnimationTypes = [
  {
    name: "running_jump",
    gif: running_jump,
    group: "jumps",
  },
  {
    name: "wave_hello_3",
    gif: wave_hello_3,
    group: "funny",
  },
  {
    name: "hip_hop_dancing",
    gif: hip_hop_dancing,
    group: "dance",
  },
  {
    name: "box_jump",
    gif: box_jump,
    group: "jumps",
  },
  { name: "boxing", gif: boxing, group: "funny" },
  {
    name: "catwalk_walk",
    gif: catwalk_walk,
    group: "walks",
  },
  {
    name: "dab_dance",
    gif: dab_dance,
    group: "dance",
  },
  {
    name: "dance",
    gif: dance,
    group: "dance",
  },
  {
    name: "dance001",
    gif: dance001,
    group: "dance",
  },
  {
    name: "dance002",
    gif: dance002,
    group: "dance",
  },
  {
    name: "floating",
    gif: floating,
    group: "funny",
  },
  {
    name: "flying_kick",
    gif: flying_kick,
    group: "jumps",
  },
  {
    name: "happy_idle",
    gif: happy_idle,
    group: "funny",
  },
  {
    name: "hip_hop_dancing2",
    gif: hip_hop_dancing2,
    group: "dance",
  },
  {
    name: "hip_hop_dancing3",
    gif: hip_hop_dancing3,
    group: "dance",
  },
  {
    name: "jab_cross",
    gif: jab_cross,
    group: "funny",
  },
  {
    name: "joyful_jump_l",
    gif: joyful_jump_l,
    group: "jumps",
  },
  {
    name: "jump",
    gif: jump,
    group: "jumps",
  },
  {
    name: "jump_attack",
    gif: jump_attack,
    group: "jumps",
  },
  {
    name: "jump_rope",
    gif: jump_rope,
    group: "jumps",
  },
  {
    name: "punching_bag",
    gif: punching_bag,
    group: "funny",
  },
  {
    name: "run",
    gif: run,
    group: "walks",
  },
  {
    name: "run_walk_jump_walk",
    gif: run_walk_jump_walk,
    group: "walks",
  },
  {
    name: "shoot_gun",
    gif: shoot_gun,
    group: "funny",
  },
  {
    name: "shuffle_dance",
    gif: shuffle_dance,
    group: "dance",
  },
  {
    name: "skipping",
    gif: skipping,
    group: "walks",
  },
  {
    name: "standard_walk",
    gif: standard_walk,
    group: "walks",
  },
  {
    name: "walk_punch_kick_jump_walk",
    gif: walk_punch_kick_jump_walk,
    group: "walks",
  },
  {
    name: "walk_sway",
    gif: walk_sway,
    group: "walks",
  },
  {
    name: "walk_swing_arms",
    gif: walk_swing_arms,
    group: "walks",
  },
  {
    name: "waving_gesture",
    gif: waving_gesture,
    group: "funny",
  },
  {
    name: "zombie_walk",
    gif: zombie_walk,
    group: "walks",
  },
];

export default AnimationTypes;
