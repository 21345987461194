import React from "react";
import { Spinner } from "react-bootstrap";
import example3 from "../assets/drawings_examples/example3.png";
import example4 from "../assets/drawings_examples/example4.png";
import example5 from "../assets/drawings_examples/example5.jpg";
import example6 from "../assets/drawings_examples/example6.png";
import { useDrawingApi } from "../hooks/useDrawingApi";
import useDrawingStore from "../hooks/useDrawingStore";

const ExamplesCarousel = () => {
  const { isLoading } = useDrawingApi((err) => {});
  const {
    setDrawing,
    setOriginalDimensions,
    setNewCompressedDrawing,
  } = useDrawingStore();
  const [imgSelection, setImgSelection] = React.useState<string>("");

  const selectImgFile = (image_name: string) => {
    switch (image_name) {
      case "example3.png":
        return example3;
      case "example4.png":
        return example4;
      case "example5.jpg":
        return example5;
      case "example6.png":
        return example6;
      default:
        return example3;
    }
  };

  /**
   * Use a pre-canned image
   * @param image_name
   */
  const handlePreCannedImage = async (image_name: string) => {
    setImgSelection(image_name);
    let img_file = selectImgFile(image_name);
    try {
      const res = await fetch(img_file);
      const blob = await res.blob();
      const imageDataUrl = URL.createObjectURL(blob);
      let newFile = new File([blob], "animation.png", {
        type: "image/png",
        lastModified: new Date().getTime(),
      });
      const tempImage = new Image();
      if (imageDataUrl !== null && imageDataUrl !== undefined)
        tempImage.src = imageDataUrl;

      // Calculate the original dimensions of img for the bouding box background.
      tempImage.onload = function (e) {
        setOriginalDimensions({
          width: tempImage.naturalWidth,
          height: tempImage.naturalHeight,
        });
      };

      setNewCompressedDrawing(newFile);
      setDrawing(imageDataUrl);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="grid-container">
      <div className="sample-grid-item">
        {isLoading && imgSelection === "example3.png" ? (
          <Spinner animation="grow" variant="primary" />
        ) : (
          <div onClick={() => handlePreCannedImage("example3.png")}>
            <img src={example3} alt="" />
            <div className="grid-item-overlay" />
          </div>
        )}
      </div>
      <div className="sample-grid-item">
        {isLoading && imgSelection === "example6.png" ? (
          <Spinner animation="grow" variant="primary" />
        ) : (
          <div onClick={() => handlePreCannedImage("example6.png")}>
            <img src={example6} alt="" />
            <div className="grid-item-overlay" />
          </div>
        )}
      </div>
      <div className="sample-grid-item">
        {isLoading && imgSelection === "example4.png" ? (
          <Spinner animation="grow" variant="primary" />
        ) : (
          <div onClick={() => handlePreCannedImage("example4.png")}>
            <img src={example4} alt="" />
            <div className="grid-item-overlay" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExamplesCarousel;
