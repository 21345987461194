import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DarkNavbar from "../components/Navbars/DarkNavbar";
import AboutModal from "../components/Modals/AboutModal";
import TosModal from "../components/Modals/TosModal";
import HomeFooter from "../components/Footers/HomeFooter";
import useLogPageView from "../hooks/useLogPageView";
import useStepperStore from "../hooks/useStepperStore";

const HomePage = () => {
  const history = useHistory();
  const { agreeTerms } = useStepperStore();
  const [showModal, setShowModal] = React.useState(false);
  const [showTOS, setShowTOS] = React.useState(false);

  useLogPageView("Home", "/");

  const handleTryDemo = () => {
    if (agreeTerms) {
      history.push("/canvas");
    } else {
      setShowTOS(true);
    }
  };

  return (
    <React.Fragment>
      <div className="main-content bg-main-dark" id="home">
        <DarkNavbar />
        <div className="home-page">
          <Container className="home-info align-content-center" fluid>
            <Row className="justify-content-between mt-4 mb-4 mb-md-4 copy-wrapper">
              <Col lg={10} md={12} sm={12} className="px-0 mb-2">
                <h2 className="mb-0 gradient-text">Animated Drawings</h2>
                <h2 className="text-white">
                  Transform static sketches into fun animations.
                </h2>
              </Col>
              <Col md="auto" className="px-0 align-content-center">
                <div className="d-flex">
                  <button
                    className="get-started-button text-white"
                    onClick={handleTryDemo}
                  >
                    Try it now
                  </button>
                </div>
              </Col>
            </Row>

            <div className="justify-content-center image-home-wrapper"></div>
          </Container>
        </div>
        <HomeFooter
          dark
          uuid={"[No ID] - Preload feedback"}
          setShowModal={setShowModal}
        />
        <AboutModal showModal={showModal} setShowModal={setShowModal} />
        <TosModal showModal={showTOS} setShowModal={setShowTOS} />
      </div>
    </React.Fragment>
  );
};

export default HomePage;
