import React from "react";
import { Navbar, Nav } from "react-bootstrap";

interface props {
  uuid?: string;
  dark?: boolean;
  setShowModal: (res: boolean) => void;
}

export default function HomeFooter({ uuid, dark, setShowModal }: props) {
  return (
    <Navbar expand="sm" bg="transparent" className="align-items-start">
      <Nav className="mr-auto home-footer">
        <Nav.Link className={dark ? `text-white` : `text-primary`}>
          ©2024 Meta
        </Nav.Link>
        <Nav.Link
          href="https://www.facebook.com/about/privacy/"
          target="_blank"
          rel="noreferrer"
          className={dark ? `text-white` : `text-primary`}
        >
          Privacy
        </Nav.Link>
        <Nav.Link
          href="/terms"
          target="_blank"
          rel="noreferrer"
          className={dark ? `text-white` : `text-primary`}
        >
          Terms
        </Nav.Link>
        <Nav.Link
          href="/usage"
          target="_blank"
          rel="noreferrer"
          className={dark ? `text-white` : `text-primary`}
        >
          Usage
        </Nav.Link>
        <Nav.Link
          href="https://www.facebook.com/policies/cookies/"
          target="_blank"
          rel="noreferrer"
          className={dark ? `text-white` : `text-primary`}
        >
          Cookies
        </Nav.Link>
      </Nav>
      <Nav className="text-right home-footer">
        <Nav.Link
          href={`https://docs.google.com/forms/d/e/1FAIpQLSfynXUEVc0YvSYGXN3BCFjl7uNthyEkxqibsNHn3pqA_Wt8Hg/viewform?entry.1387562397=${
            uuid !== "" ? uuid : "Preload Step [No ID]"
          }`}
          target="_blank"
          rel="noreferrer"
          className={dark ? `text-white` : `text-primary`}
        >
          Feedback
          <i className="bi bi-info-circle-fill ml-2 h3"></i>
        </Nav.Link>
      </Nav>
    </Navbar>
  );
}
