import React from "react";
import CookieConsent from "react-cookie-consent";

interface Props {
  onAccept: () => any;
}

const CookieBanner = ({ onAccept }: Props) => {
  return (
    <CookieConsent
      style={{
        padding: "12px",
        backgroundColor: "white",
        color: "black",
      }}
      overlay
      disableButtonStyles
      enableDeclineButton
      overlayClasses="consent-overlay"
      containerClasses="consent-container"
      contentClasses="consent-content"
      buttonClasses="btn accept-button"
      declineButtonClasses="btn decline-button"
      buttonWrapperClasses="btn-wrapper"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="animated_drawings"
      onAccept={onAccept}
      onDecline={() => {}}
    >
      Allow the use of cookies from Meta on this browser? To find out more about
      the use of cookies, see our{" "}
      <a
        href="https://www.facebook.com/about/privacy/"
        target="_blank"
        rel="noreferrer"
      >
        <b>Privacy Policy</b>
      </a>{" "}
      and{" "}
      <a
        href="https://www.facebook.com/policies/cookies/"
        target="_blank"
        rel="noreferrer"
      >
        <b>Cookies.</b>
      </a>
    </CookieConsent>
  );
};

export default CookieBanner;
