import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import useStepperStore from "../../hooks/useStepperStore";

interface modalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const TosModal = ({ showModal, setShowModal }: modalProps) => {
  const history = useHistory();
  const { setAgreeTerms } = useStepperStore();

  const handleAcceptTOS = () => {
    setAgreeTerms(true);
    setShowModal(false);
    history.push("/canvas");
  };

  return (
    <Fragment>
      <Modal
        className="modal-tos"
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        animation={false}
        centered
      >
        <Modal.Header className="text-white py-2" closeButton></Modal.Header>
        <Modal.Body className="text-white px-sm-4 py-0">
          <h3>Before you begin the demo</h3>
          <ul className="my-2" style={{ fontSize: "14px" }}>
            <li className="my-3">
              This is a research demo and may not be used for any commercial
              purpose(s).
            </li>
            <li className="my-3">
              This research demo is not open to residents of, or those accessing the demo from, the States of Illinois or Texas.
            </li>
            <li className="my-3">
              As with all AI systems, there are inherent risks that the model
              could generate inaccurate outputs.
            </li>
          </ul>
          <p style={{ fontSize: "12px" }} className="mb-0">
            By clicking Accept, you agree to be bound by the{" "}
            <a
              href="/terms"
              target="_blank"
              rel="noreferrer"
              className="bold text-white decoration"
            >
              <u>Animated Drawings Demo Supplemental Terms of Service</u>
            </a>{" "}
            and the{" "}
            <a
              href="/usage"
              target="_blank"
              rel="noreferrer"
              className="bold text-white decoration"
            >
              <u>Acceptable Use Policy</u>.
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer className="pb-4">
          <Button
            size="lg"
            className="mx-auto"
            variant="secondary"
            onClick={handleAcceptTOS}
            block
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default TosModal;
