import create from "zustand";
import { Pose } from "../components/Canvas/PoseEditor";
import { BoundingBox } from "../components/Canvas/CanvasBoundingBox";

type DrawingState = {
  drawing: string; // Base64
  newCompressedDrawing: File | any;
  originalDimension: any;
  uuid: string;
  croppedImgDimensions: any;
  pose: Pose;
  boundingBox: BoundingBox;
  animationType: AnimationType;
  renderingVideo: boolean;
  imageUrlPose?: string; // Cropped Image
  imageUrlMask?: string; // Mask Image
  setDrawing: (imported: any) => void;
  setNewCompressedDrawing: (file: File | null) => void;
  setOriginalDimensions: (data: any) => void;
  setUuid: (uuid: string) => void;
  setCroppedImgDimensions: (data: any) => void;
  setPose: (n_pose: Pose) => void;
  setBox: (dimensions: any) => void;
  setAnimationType: (ani_type: any) => void;
  setRenderingVideo: (state: boolean) => void;
  setImageUrlPose: (url: string | any) => void;
  setImageUrlMask: (url: string | any) => void;
};

enum AnimationType {
  RunJump = "running_jump",
  Wave = "wave_hello_3",
  Dance = "hip_hop_dancing",
  BoxJump = "box_jump",
  Boxing = "boxing",
  CatWalk = "catwalk_walk",
  DabDance = "dab_dance",
  Dance001 = "dance_001",
}

const useDrawingStore = create<DrawingState>((set) => ({
  drawing: "",
  newCompressedDrawing: null,
  originalDimension: { width: 10, height: 10 },
  uuid: "",
  croppedImgDimensions: { width: 0, height: 0 },
  pose: { nodes: [], edges: [] },
  boundingBox: { x: 200, width: 100, y: 200, height: 100, id: "1" },
  animationType: AnimationType.RunJump,
  renderingVideo: false,
  imageUrlPose: undefined,
  imageUrlMask: undefined,
  setDrawing: (imported) => set(() => ({ drawing: imported })),
  setNewCompressedDrawing: (file) =>
    set(() => ({ newCompressedDrawing: file })),
  setOriginalDimensions: (data) => set(() => ({ originalDimension: data })),
  setUuid: (uuid) => set(() => ({ uuid: uuid })),
  setCroppedImgDimensions: (data) =>
    set(() => ({ croppedImgDimensions: data })),
  setPose: (n_pose) => set(() => ({ pose: n_pose })),
  setBox: (newBB) => set(() => ({ boundingBox: newBB })),
  setAnimationType: (ani_type) => set(() => ({ animationType: ani_type })),
  setRenderingVideo: (rendering) => set(() => ({ renderingVideo: rendering })),
  setImageUrlPose: (url) => set(() => ({ imageUrlPose: url })),
  setImageUrlMask: (url) => set(() => ({ imageUrlMask: url })),
}));

export default useDrawingStore;
