import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import META_LOGO from "../../assets/customIcons/Meta_logo_white_t.png";

const DarkNavbar = () => {
  return (
    <Navbar
      className="py-2 py-md-3 px-md-6 justify-content-between navbar-dark"
      expand="lg"
    >
      <Navbar.Brand href="#" className="text-white bold-link">
        <img alt="drawing" src={META_LOGO} width={67} />
      </Navbar.Brand>

      {/* Desktop */}
      <Nav className="ml-auto d-none d-sm-block">
        <Nav.Link
          href="https://ai.meta.com/blog/ai-dataset-animation-drawings/"
          target="_blank"
          rel="noreferrer"
          className="text-white light-link mx-1 mx-md-0 d-inline"
        >
          Blog <i className="bi bi-arrow-up-right mx-1"></i>
        </Nav.Link>
        <Nav.Link
          href={"https://aidemos.meta.com/"}
          target="_blank"
          rel="noreferrer"
          className="text-white light-link mx-1 mx-md-0 d-inline"
        >
          AI Demos <i className="bi bi-arrow-up-right mx-1"></i>
        </Nav.Link>
      </Nav>

      {/* Mobile */}
      <NavDropdown
        className="d-block d-sm-none"
        alignRight
        title={<i className="bi bi-list dropdown-icon"></i>}
        id="basic-navbar-nav"
      >
        <NavDropdown.Item
          href="https://ai.meta.com/blog/ai-dataset-animation-drawings/"
          target="_blank"
          rel="noreferrer"
          className="text-white light-link mx-1 mx-md-0"
        >
          Blog <i className="bi bi-arrow-up-right mx-1"></i>
        </NavDropdown.Item>
        <NavDropdown.Item
          href={"https://aidemos.meta.com/"}
          target="_blank"
          rel="noreferrer"
          className="text-white light-link mx-1 mx-md-0"
        >
          AI Demos <i className="bi bi-arrow-up-right mx-1"></i>
        </NavDropdown.Item>
      </NavDropdown>
    </Navbar>
  );
};

export default DarkNavbar;
