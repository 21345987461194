import React from "react";
import { Navbar, Container, Row, Col } from "react-bootstrap";
import HomeFooter from "../components/Footers/HomeFooter";
import AboutModal from "../components/Modals/AboutModal";
import useLogPageView from "../hooks/useLogPageView";

export default function UsagePage() {
  useLogPageView("Use", "");
  const [showModal, setShowModal] = React.useState(false);
  const numberedList = {
    listStyleType: 'decimal' /* Numbers */,
  };

  const lowercaseList = {
    listStyleType: 'lower-alpha' /* Lowercase letters */,
  };

  const romanList = {
    listStyleType: 'lower-roman' /* Lowercase Roman numerals */,
  };
  return (
    <div className="main-content bg-main">
      <Navbar bg="transparent">
        <Navbar.Brand href="/">
          <div className="ad-logo-title">
            <h2 className="mb-3">
              ANIMATED <span className="text-info">DRAWINGS</span>
            </h2>
          </div>
        </Navbar.Brand>
      </Navbar>
      <div className="terms-page mb-2">
        <Container className="">
          <Row className="justify-content-center mt-2">
            <Col lg={10} md={10} xs={12}>
              <h2 className="mb-4 text-center">Animated Drawings Acceptable Use Policy</h2>
              <br />
              <p>
                Meta is committed to promoting safe and fair use of its tools and features, including the Materials as defined in the Animated Drawings Demo Supplemental Terms of Service. If you access or use Animated Drawings, you agree to this Acceptable Use Policy (“Policy”). 
                The most recent copy of this Policy can be found 
                <a href="https://sketch.metademolab.com/usage"> here</a>.
              </p>
              <h3 className="text-left">Prohibited Uses</h3>
              <p >We want everyone to use Animated Drawings safely and responsibly. You agree that you
                will not use, or allow others to use, Animated Drawings to:</p>
              <ol style={numberedList}>
                <li ><span >Permit access to or use of Animated Drawings to any person under the
                  age of 18 (or the age of majority in the jurisdiction from which you are accessing Animated
                  Drawings)</span></li>
                <li ><span >Violate the law or others&rsquo; rights, including, but not limited
                  to:</span></li>

                <ol style={lowercaseList}>
                  <li ><span >Engage in, promote, contribute to, encourage, plan, incite, or
                    further illegal or unlawful activity or content, such as:</span></li>

                  <ol style={romanList}>
                    <li ><span >Violence or terrorism</span></li>
                    <li ><span >Exploitation or harm to children, including the solicitation,
                      creation, acquisition, or dissemination of child exploitative content or failure to report Child Sexual
                      Abuse Material</span></li>
                    <li ><span >Human trafficking, exploitation, and sexual violence</span></li>
                    <li ><span >The illegal distribution of information or materials to minors,
                      including obscene materials, or failure to employ legally required age-gating in connection with such
                      information or materials</span></li>
                    <li ><span >Sexual solicitation</span></li>
                    <li ><span >Any other criminal activity</span></li>
                  </ol>

                  <li ><span >Engage in, promote, incite, or facilitate the harassment, abuse,
                    threatening, or bullying of individuals or groups of individuals</span></li>
                  <li ><span >Engage in, promote, incite, or facilitate discrimination or other
                    unlawful or harmful conduct in the provision of employment, employment benefits, credit, housing, other
                    economic benefits, or other essential goods and services</span></li>
                  <li ><span >Collect, process, disclose, or infer health, demographic, biometric,
                    or other sensitive personal or private information about individuals</span></li>
                  <li ><span >Engage in or facilitate any action that infringes, misappropriates,
                    or otherwise violates any third-party rights, including:</span></li>

                  <ol style={romanList}>

                    <li ><span >Intellectual property rights</span></li>
                    <li ><span >The outputs or results of any products or services using Animated
                      Drawings</span></li>
                  </ol>

                  <li ><span >Create or facilitate the creation of malicious code, malware,
                    computer viruses or do anything else that could disable, overburden, interfere with or impair the proper
                    working, integrity, operation or appearance of a website or computer system</span></li>

                </ol>
                <li ><span >Engage in, promote, incite, facilitate or assist in the planning or
                  development of activities that present a risk of death or bodily harm to individuals, including, but not
                  limited to, the use of the Materials related to the following:</span></li>

                <ol style={lowercaseList}>

                  <li ><span >Military, warfare, nuclear industries or applications, espionage,
                    use for materials or activities that are subject to the International Traffic Arms Regulations (ITAR)
                    maintained by the United States Department of State</span></li>
                  <li ><span >Guns and illegal weapons (including weapon development)</span></li>
                  <li ><span >Illegal drugs and regulated/controlled substances</span></li>
                  <li ><span >Operation of critical infrastructure, transportation technologies,
                    or heavy machinery</span></li>
                  <li ><span >Self-harm or harm to others, including suicide, cutting, and eating
                    disorders</span></li>
                  <li ><span >Any content intended to incite or promote violence, abuse, or any
                    infliction of bodily harm to an individual</span></li>
                </ol>

                <li ><span >Intentionally deceive or mislead others, including, but not limited
                  to, use of the Materials to:</span></li>

                <ol style={lowercaseList}>

                  <li ><span >Promote or further fraud or disinformation, including scams,
                    phishing, multi-level marketing or pyramid schemes, or any other fraudulent activities</span></li>
                  <li ><span >Promote or further defamatory content, including the creation of
                    defamatory statements or images</span></li>
                  <li ><span >Promote or distribute spam</span></li>
                  <li ><span >Impersonate another individual without consent, authorization, or
                    legal right, including non-consensual sexual imagery</span></li>
                  <li ><span >Facilitate minor grooming, including generating content designed to
                    impersonate a minor</span></li>
                  <li ><span >Remove, alter, or otherwise tamper with any watermark placed on an
                    output by Meta</span></li>
                  <li ><span >Facilitate false online engagement, including fake reviews and other
                    means of fake online engagement</span></li>
                </ol>

                <li ><span >Rely on outputs for government decision-making in high risk
                  contexts, including, for example, law enforcement, criminal justice, immigration, or asylum, without a
                  qualified person reviewing the outputs</span></li>
                <li ><span >Engage in any decision-making related to health, financial, safety,
                  or legal matters</span></li>
                <li ><span >Create, develop, access, or disseminate adult content, including in
                  relation to:</span></li>

                <ol style={lowercaseList}>

                  <li ><span >Erotic, sexual, or romantic chats or imagery</span></li>
                  <li ><span >Sexual solicitation</span></li>
                  <li ><span >Pornography</span></li>
                  <li ><span >Content that describes or promotes sexual or adult services</span>
                  </li>
                </ol>

                <li ><span >Create, develop, access, or disseminate graphic, disturbing, or
                  otherwise offensive content, including, but not limited to:</span></li>

                <ol style={lowercaseList}>

                  <li ><span >Gore, dismemberment, violent death, or other gruesome
                    depictions</span></li>
                  <li ><span >Animal suffering or death</span></li>
                  <li ><span >Depictions of historical mass death events </span></li>
                </ol>

                <li ><span >Engage in any commercial and/or non-personal uses of the Animated
                  Drawings Demo</span></li>


              </ol>
              <p ><span ></span></p>
              <p ><span >Please report any violation of this Policy, software &ldquo;bug,&rdquo; or other
                problems that could lead to a violation of this Policy through one of the following </span>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfynXUEVc0YvSYGXN3BCFjl7uNthyEkxqibsNHn3pqA_Wt8Hg/">reporting form.</a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <HomeFooter
        uuid={"[No ID] - Preload feedback"}
        setShowModal={setShowModal}
      />
      <AboutModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}
